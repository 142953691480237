import React from 'react';
import styled from "styled-components";
import {Link} from "gatsby";
import LogoIPST from "../images/logoIPST.svg"

const Container = styled.div`
  display: flex;
  background-color: #445FB7;
  justify-content: center;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1110px;
  height: 102px;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    height: 102px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    height: 92px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    height: 82px;
  }
`;

const NavLink = styled(Link)`
  font-family: Montserrat;
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 22px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 960px) {
    font-size: 12px;
    margin-right: 17px;
  }
  @media screen and (max-width: 800px) {
    font-size: 10px;
    margin-right: 14px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 22px;
  color: white;
  margin-right: 16px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    margin-right: 12px;
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
    margin-right: 9px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.img`
  @media screen and (max-width: 960px) {
    width: 70px;
  }
  @media screen and (max-width: 800px) {
    width: 60px;
  }
`;

const Footer = () => {
    return (
        <Container>
            <FooterContainer>
                <div>
                    <NavLink to="/about">
                        О компании
                    </NavLink>
                    <NavLink to="/projects">
                        Проекты
                    </NavLink>
                    <NavLink to="/geography">
                        География деятельности
                    </NavLink>
                    <NavLink to="/partners">
                        Партнеры
                    </NavLink>
                    <NavLink to="/vacancies">
                        Вакансии
                    </NavLink>
                    <NavLink to="/contacts">
                        Контакты
                    </NavLink>
                </div>
                <RowContainer>
                    <Text>
                        Разработано
                    </Text>
                    <Image src={LogoIPST} alt="LogoIPST"/>
                </RowContainer>
            </FooterContainer>
        </Container>
    )
}

export default Footer;